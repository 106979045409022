import axios, { AxiosError } from "axios";
import {
  getAccessToken,
  getRefreshToken,
  storeAccessToken,
} from "./StorageService";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

API.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (resp) => resp,
  async (error: AxiosError) => {
    if (error.response?.status === 403) {
      try {
        const accessToken = await refreshAccessToken();
        storeAccessToken(accessToken);
        error.config.headers["Authorization"] = `Bearer ${accessToken}`;
      } catch {
        throw Error("cannot refresh access token");
      }
      return axios(error.config);
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async (): Promise<string> => {
  const endpoint = `${API.defaults.baseURL}/v1/auth/refresh`;
  const payload = { refresh_token: getRefreshToken() };

  const { status, data } = await axios.post(endpoint, payload);
  if (status !== 200) throw Error(`status code: ${status}`);

  return data.access_token as string;
};
