import React from 'react';
import DataGrid, { HeaderSortingProps } from '../components/DataGrid';
import { Box, Button, Grid, Paper, SortDirection, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import MetricService, { Summary } from '../services/MetricService';
import { usePopup } from '../providers/PopupProvider';
import { useAuthentication } from '../providers/AuthProvider';
import './Metrics.css';
import ErrorHandler from '../services/ErrorHandler';
import KTableCell from '../components/KTableCell';

const cells = [
  {
    id: 'date',
    label: 'Date',
    numeric: false,
    sortable: true,
  },
  {
    id: 'domain',
    label: 'Domain',
    numeric: false,
    sortable: true,
  },
  {
    id: 'dimension',
    label: 'Dimension',
    numeric: false,
    sortable: true,
  },
  {
    id: 'requests',
    label: 'Requests',
    numeric: true,
    sortable: true,
  },
  {
    id: 'responses',
    label: 'Responses',
    numeric: true,
    sortable: true,
  },
  {
    id: 'win_notices',
    label: 'Win Notices',
    numeric: true,
    sortable: true,
  },
  {
    id: 'impressions',
    label: 'Impressions',
    numeric: true,
    sortable: true,
  },
  {
    id: 'average_cpm_rate',
    label: 'Avg. CPM Rate',
    numeric: true,
    sortable: false,
  },
  {
    id: 'clicks',
    label: 'Clicks',
    numeric: true,
    sortable: true,
  },
  {
    id: 'average_ctr',
    label: 'Avg. CTR',
    numeric: true,
    sortable: true,
  },
  {
    id: 'revenue',
    label: 'Revenue',
    numeric: true,
    sortable: true,
  },
  {
    id: 'average_bid_cpm',
    label: 'Avg. Bid CPM',
    numeric: true,
    sortable: true,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  summary: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  exportButton: {
    marginRight: theme.spacing(2),
  },
}));

const Metrics: React.FC = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [totalElements, setTotalElements] = React.useState(0);

  const [summary, setSummary] = React.useState<Summary | null>(null);

  const [startDate, setStartDate] = React.useState<moment.Moment | null>(
    moment().subtract(7, 'day')
  );

  const [endDate, setEndDate] = React.useState<moment.Moment | null>(moment());
  const [focus, setFocus] = React.useState<FocusedInputShape | null>(null);

  const [sorting, setSorting] = React.useState<HeaderSortingProps>({
    fields: {
      date: 'desc' as SortDirection,
      domain: 'asc' as SortDirection,
      dimension: false  as SortDirection,
      requests: false as SortDirection,
      responses: false as SortDirection,
      win_notices: false as SortDirection,
      average_win_rate: false as SortDirection,
      impressions: false as SortDirection,
      clicks: false as SortDirection,
      average_ctr: false as SortDirection,
      revenue: false as SortDirection,
      average_bid_cpm: false as SortDirection,
    },
    order: ['date', 'domain'],
  });

  const handleSortingChange = (key: string) => {
    const order = [...sorting.order];
    const index = order.indexOf(key);

    switch (sorting.fields[key]) {
      case false:
        if (index === -1) order.push(key);
        setSorting({ fields: { ...sorting.fields, [key]: 'desc' }, order });
        break;
      case 'desc':
        if (index === -1) order.push(key);
        setSorting({ fields: { ...sorting.fields, [key]: 'asc' }, order });
        break;
      default:
        if (index > -1) order.splice(index, 1);
        setSorting({ fields: { ...sorting.fields, [key]: false }, order });
        break;
    }
  };

  const { showPopup } = usePopup();
  const { logout } = useAuthentication();

  const handlerExportButtonClick = async () => {
    if (!startDate || !endDate) {
      return;
    }

    try {
      const sort = sorting.order
        .map((key) => ({ key, direction: sorting.fields[key] }))
        .filter(({ direction }) => direction)
        .map(({ key, direction }) => `${key},${direction}`);

      const data = await MetricService.exportAsCSV({
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD'),
        sort,
      });

      const tempAnchor = document.createElement('a');
      if (tempAnchor.download !== undefined) {
        const downloadURL = URL.createObjectURL(data.blob);
        tempAnchor.setAttribute('href', downloadURL);
        tempAnchor.setAttribute('download', data.filename);
        tempAnchor.style.visibility = 'hidden';
        document.body.appendChild(tempAnchor);
        tempAnchor.click();
        document.body.removeChild(tempAnchor);
      }
    } catch (e: any) {
      ErrorHandler.checkAPICallError(e, showPopup, logout);
    }
  };

  const filters = [
    <Box
      display='flex'
      flexDirection='row-reverse'
      px={2}
      py={2}
      key='date-range-picker'
    >
      <DateRangePicker
        hideKeyboardShortcutsPanel
        startDate={startDate}
        startDateId='start-date'
        endDate={endDate}
        endDateId='end-date'
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        focusedInput={focus}
        onFocusChange={(focus) => setFocus(focus)}
        isOutsideRange={(date) => date.isAfter(moment(), 'day')}
        minimumNights={0}
      />
      <Button
        variant='contained'
        color='primary'
        className={classes.exportButton}
        onClick={handlerExportButtonClick}
      >
        Export
      </Button>
    </Box>,
  ];

  const header = {
    cells,
    sorting,
    onSortingChange: handleSortingChange,
  };

  const [rows, setRows] = React.useState<JSX.Element[]>([]);

  const reloadSummary = React.useCallback(async () => {
    if (!startDate || !endDate) {
      return;
    }

    try {
      setSummary(
        await MetricService.summary({
          start: startDate.format('YYYY-MM-DD'),
          end: endDate.format('YYYY-MM-DD'),
        })
      );
    } catch (e: any) {
      ErrorHandler.checkAPICallError(e, showPopup, logout);
    }
  }, [startDate, endDate, setSummary, showPopup, logout]);

  const reloadMetrics = React.useCallback(async () => {
    if (!startDate || !endDate) {
      return;
    }

    try {
      const sort = sorting.order
        .map((key) => ({ key, direction: sorting.fields[key] }))
        .filter(({ direction }) => direction)
        .map(({ key, direction }) => `${key},${direction}`);

      const { metrics, total_elements } = await MetricService.list({
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD'),
        page,
        size,
        sort,
      });

      const tableCellOverrides = {
        align: 'right',
      };

      const rows = metrics.map<JSX.Element>((metric, i) => (
        <TableRow key={`data-grid-row-${i}`}>
          <KTableCell child={moment(metric.date).format('YYYY-MM-DD')} />
          <KTableCell child={metric.domain} />
          <KTableCell child={metric.dimension}/>
          <KTableCell overrides={tableCellOverrides} child={metric.requests} />
          <KTableCell overrides={tableCellOverrides} child={metric.responses} />
          <KTableCell
            overrides={tableCellOverrides}
            child={metric.win_notices}
          />
          <KTableCell
            overrides={tableCellOverrides}
            child={metric.impressions}
          />
          <KTableCell
            overrides={tableCellOverrides}
            child={`$${(
              Math.round(metric.average_cpm_rate * 10000) / 10000
            ).toFixed(4)}`}
          />
          <KTableCell overrides={tableCellOverrides} child={metric.clicks} />
          <KTableCell
            overrides={tableCellOverrides}
            child={`${Math.floor(metric.average_ctr * 100 * 100) / 100}%`}
          />
          <KTableCell
            overrides={tableCellOverrides}
            child={`$${Math.floor(metric.revenue * 100) / 100}`}
          />
          <KTableCell
            overrides={tableCellOverrides}
            child={`$${metric.average_bid_cpm.toFixed(5)}`}
          />
        </TableRow>
      ));

      setRows(rows);
      setTotalElements(total_elements);
    } catch (e: any) {
      ErrorHandler.checkAPICallError(e, showPopup, logout);
    }
  }, [
    page,
    size,
    startDate,
    endDate,
    sorting,
    setRows,
    setTotalElements,
    showPopup,
    logout,
  ]);

  React.useEffect(() => {
    reloadSummary();
  }, [startDate, endDate, reloadSummary]);

  React.useEffect(() => {
    reloadMetrics();
  }, [page, size, startDate, endDate, sorting, reloadMetrics]);

  return (
    <React.Fragment>
      {summary ? (
        <div className={classes.container}>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
              <Paper
                className={classes.summary}
                variant='outlined'
                elevation={0}
              >
                Incoming Request
                <br />
                {summary.total_bid_requests}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper
                className={classes.summary}
                variant='outlined'
                elevation={0}
              >
                Valid Request
                <br />
                {summary.valid_requests}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper
                className={classes.summary}
                variant='outlined'
                elevation={0}
              >
                Total Impressions
                <br />
                {summary.total_impressions}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper
                className={classes.summary}
                variant='outlined'
                elevation={0}
              >
                Average Win Rate
                <br />
                {Math.round(summary.average_win_rate * 100 * 100) / 100}%
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper
                className={classes.summary}
                variant='outlined'
                elevation={0}
              >
                Total Earnings
                <br />${Math.floor(summary.total_revenue * 100) / 100}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper
                className={classes.summary}
                variant='outlined'
                elevation={0}
              >
                Average Bid CPM
                <br />${summary.average_bid_cpm.toFixed(5)}
              </Paper>
            </Grid>
          </Grid>
        </div>
      ) : undefined}
      <DataGrid
        filters={filters}
        header={header}
        rowHeight={53}
        rows={rows}
        page={page}
        size={size}
        totalElements={totalElements}
        onPageChange={setPage}
        onSizeChange={setSize}
      />
    </React.Fragment>
  );
};

export default Metrics;
