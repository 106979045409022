const AUTH_KEY = (slut: string): string => `kaiads.dashboard.auth.${slut}`;
const ACCESS_TOKEN_KEY = AUTH_KEY("access_token");
const REFRESH_TOKEN_KEY = AUTH_KEY("refresh_token");

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const storeTokenPair = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY) || "";
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY) || "";
};

export const removeTokenPair = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};
