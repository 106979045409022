import React from "react";
import { useField } from "@unform/core";
import { TextField, TextFieldProps } from "@mui/material";

type InputProps = TextFieldProps & {
  name: string;
};

const Input: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  return (
    <TextField
      id={fieldName}
      name={name}
      inputRef={inputRef}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error}
      {...rest}
    />
  );
};

export default Input;
