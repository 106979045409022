import React from "react";
import { Box, Button, Container } from "@mui/material";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Input from "../components/Input";
import { Credential, useAuthentication } from "../providers/AuthProvider";
import { usePopup } from "../providers/PopupProvider";
import "./Login.css";

const Login: React.FC = () => {
  const formRef = React.useRef<FormHandles>(null);
  const { login } = useAuthentication();
  const { showPopup } = usePopup();

  const handleLogin = (credential: Credential) =>
    login(credential).catch((e: Error) => {
      if (e.message.includes("204")) {
        showPopup("error", "Invalid username or password!");
      } else {
        showPopup("error", "Network error, please try again later!");
      }
    });

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm" className="loginBox">
        <Form onSubmit={handleLogin} ref={formRef}>
          <Input
            required
            fullWidth
            autoFocus
            name="username"
            label="Username"
            type="text"
            variant="outlined"
            margin="normal"
          />
          <Input
            required
            fullWidth
            autoFocus
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
          />
          <Box my={2}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Login
            </Button>
          </Box>
        </Form>
      </Container>
    </Box>
  );
};

export default Login;
