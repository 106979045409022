import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "../providers/AuthProvider";

interface RouteProps {
  auth?: boolean;
  children: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ auth = false, children: Component }) => {
  const { authenticated } = useAuthentication();
  if (authenticated === auth) {
    return <Component />;
  }
  return <Navigate to={authenticated ? "/app" : "/login"} />;
};

export default Route;
