import React from "react";
import AuthService from "../services/AuthService";
import {
  getAccessToken,
  getRefreshToken,
  removeTokenPair,
  storeTokenPair,
} from "../services/StorageService";

export interface Credential {
  username: string;
  password: string;
}

interface AuthContext {
  authenticated: boolean;
  login(credential: Credential): Promise<void>;
  logout(): void;
}

const Context = React.createContext<AuthContext>({} as AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [accessToken, setAccessToken] = React.useState<string>(
    getAccessToken()
  );

  const [refreshToken, setRefreshToken] = React.useState<string>(
    getRefreshToken()
  );

  const login = React.useCallback(
    async (credential: Credential) => {
      const resp = await AuthService.login({
        username: credential.username,
        password: credential.password,
      });
      storeTokenPair(resp.access_token, resp.refresh_token);
      setAccessToken(resp.access_token);
      setRefreshToken(resp.refresh_token);
    },
    [setAccessToken, setRefreshToken]
  );

  const logout = React.useCallback(() => {
    removeTokenPair();
    setAccessToken("");
    setRefreshToken("");
  }, []);

  const state = {
    authenticated: !!accessToken && !!refreshToken,
    login,
    logout,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useAuthentication = (): AuthContext => React.useContext(Context);
