const checkAPICallError = (
  e: any,
  showPopup: (severity: string, message: string) => void,
  logout: () => void
) => {
  if (e.message === "cannot refresh access token") {
    showPopup("error", "Token expired, please login again!");
    logout();
  } else {
    showPopup("error", "Network error, please try again later!");
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  checkAPICallError,
};
