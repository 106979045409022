import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Popper,
} from '@mui/material';

export interface HeaderCellProps {
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
  selected: boolean;
}

interface SelectProps {
  cells: HeaderCellProps[];
  open: boolean;
  anchorEl: HTMLElement | null;
  onDataChange(updatedHeaders: HeaderCellProps[]): void;
}

const CustomSelect: React.FC<SelectProps> = ({
  cells,
  open,
  anchorEl,
  onDataChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const updatedHeaders = cells.map((cell, i) => {
      if (i === index) {
        return {
          ...cell,
          selected: !cell.selected,
        }
      }
      return cell
    })

    onDataChange(updatedHeaders)
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement='left'
      modifiers={[
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [200, 3]
          }
        }
      ]}
    >
      <Box
        bgcolor="#fafafa"
        border={1}
        borderColor='#dcdcdc'
        display='flex'
        flexDirection='column'
        width='15rem'
        padding='0 8%'
      >
      <h3>Display Headers</h3>
      {cells.map((cell, index) => (
        <FormControlLabel
          key={cell.id}
          control={
            <Checkbox
              key={cell.id}
              checked={cell.selected}
              color='default'
              onChange={e => handleChange(e, index)}
            />
          }
          label={cell.label}
        />
      ))}
      </Box>
    </Popper>

  )
};

export default CustomSelect;
