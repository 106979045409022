import React from 'react';
import {
  Paper,
  SortDirection,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import KTableCell from './KTableCell';

interface HeaderCellProps {
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

export interface HeaderSortingProps {
  fields: { [key: string]: SortDirection };
  order: string[];
}

interface HeaderProps {
  cells: HeaderCellProps[];
  sorting: HeaderSortingProps;
  onSortingChange(key: string): void;
}

const Header: React.FC<HeaderProps> = ({ cells, sorting, onSortingChange }) => (
  <TableHead>
    <TableRow>
      {cells.map((cell) => {
        const tableCellOverrides = {
          align: cell.numeric ? 'right' : 'left',
          sortDirection: sorting.fields[cell.id],
        };

        return (
          <KTableCell
            key={cell.id}
            overrides={tableCellOverrides}
            child={
              cell.sortable ? (
                <TableSortLabel
                  active={!!sorting.fields[cell.id]}
                  direction={
                    sorting.fields[cell.id]
                      ? (sorting.fields[cell.id] as 'asc' | 'desc')
                      : undefined
                  }
                  hideSortIcon={!cell.sortable}
                  onClick={() => onSortingChange(cell.id)}
                >
                  {cell.label}
                </TableSortLabel>
              ) : (
                cell.label
              )
            }
          />
        );
      })}
    </TableRow>
  </TableHead>
);

interface DataGridProps {
  filters?: JSX.Element[];
  header: HeaderProps;
  rowHeight: number;
  rows: JSX.Element[];
  page: number;
  size: number;
  totalElements: number;
  onPageChange(page: number): void;
  onSizeChange(size: number): void;
}

const DataGrid: React.FC<DataGridProps> = ({
  filters,
  header,
  rowHeight,
  rows,
  page,
  size,
  totalElements,
  onPageChange,
  onSizeChange,
}) => {
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ): void => {
    onPageChange(page + 1);
  };

  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSizeChange(parseInt(event.target.value));
  };

  // const emptyRows = size - rows.length;

  return (
    <Paper>
      {filters}
      <TableContainer>
        <Table size={determineTableSize(rowHeight)}>
          <Header {...header} />
          <TableBody>
            {rows}
            {/*{emptyRows > 0 && (*/}
            {/*  <TableRow style={{ height: rowHeight * emptyRows }}>*/}
            {/*    <TableCell colSpan={header.cells.length} />*/}
            {/*  </TableRow>*/}
            {/*)}*/}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={totalElements}
        page={page - 1}
        rowsPerPage={size}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
      />
    </Paper>
  );
};

const determineTableSize = (rowHeight: number): ('small' | 'medium') => {
  if (rowHeight <= 33) return 'small';
  return 'medium';
};

export default DataGrid;
