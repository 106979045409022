import React from "react";

interface Popup {
  severity: string;
  message: string;
}

interface PopupContext {
  popup: Popup | null;
  showPopup(severity: string, message: string): void;
  closePopup(): void;
}

const Context = React.createContext<PopupContext>({} as PopupContext);

export const PopupProvider: React.FC = ({ children }) => {
  const [popup, setPopup] = React.useState<Popup | null>(null);

  const showPopup = React.useCallback(
    (severity: string, message: string) => {
      setPopup({ severity, message });
    },
    [setPopup]
  );

  const closePopup = React.useCallback(() => setPopup(null), []);

  const state = {
    popup,
    showPopup,
    closePopup,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const usePopup = (): PopupContext => React.useContext(Context);
