import React from 'react';
import { TableCell } from '@mui/material';

interface KTableCellProps {
  overrides?: any;
  child: string | number | JSX.Element | JSX.Element[];
  show?: boolean;
  allowDownload?: boolean;
  data?: string;
}

const KTableCell: React.FC<KTableCellProps> = ({
  overrides,
  child,
  show = true,
  allowDownload,
  data,
}) => {
  if (!show) return null;

  return allowDownload ? (
    <TableCell {...overrides}>
      <div className='kTableCell_overlay_container'>
        <div className='kTableCell_overlay' onClick={() => download(data)}></div>
        {child}
      </div>
    </TableCell>
  ) : (
    <TableCell {...overrides}>{child}</TableCell>
  );
};

const download = (string: any) => {
  const blob = new Blob([string], { type: 'text/html' });

  const elem = document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.setAttribute('download', 'output.html');
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export default KTableCell;
