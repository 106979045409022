import React from 'react';
import { Campaign } from '../services/CampaignService';

// It removes script and anchor tags
// to prevent clicking and sending events
function cleanupAdm(rawAdm: string): string {
  const scriptTag = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi;
  const anchor = /\.*<a href=(.*?)>|<\/a>\.*/g

  const inactiveAdm = rawAdm
    .replaceAll(scriptTag, '')
    .replaceAll(anchor, '')

  return inactiveAdm;
}

const CellImage: React.FC<{campaign: Campaign}> = ({ campaign }) => {
  const isOrtb = campaign.cpc === false;
  const hasImageURL = campaign.image_url !== '';

  /* Render adm if it's an oRTB campaign,
   * otherwise render image by image_url
   * otherwise return show '-'
   */
  if (isOrtb) {
    const adm = cleanupAdm(campaign.adm);

    return (
      <iframe
        title={campaign.id}
        width="auto"
        height="100"
        srcDoc={adm}
      >
      </iframe>)
  } else if (hasImageURL)  {
    return (<img src={campaign.image_url} height='100' width='auto' alt='' />);
  }
  return (<span>-</span>)
}

export default CellImage;
