import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { RouteObject } from "react-router";
import Route from "./components/Route";
import { AuthProvider } from "./providers/AuthProvider";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Metrics from "./pages/Metrics";
import Campaigns from "./pages/Campaigns";
import { PopupProvider } from "./providers/PopupProvider";
import { ThemeProvider } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import "./App.css";
import { createTheme } from "@mui/material";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const routes: RouteObject[] = [
  {
    path: "app",
    element: <Route auth children={Layout} />,
    children: [
      {
        path: "metrics",
        element: <Metrics />,
      },
      {
        path: "campaigns",
        element: <Campaigns />,
      },
      {
        index: true,
        element: <Navigate to="metrics" />,
      },
    ],
  },
  {
    path: "/",
    element: <Route auth children={Layout} />,
    children: [
      {
        index: true,
        element: <Navigate to="/app/metrics" />,
      },
    ],
  },
  {
    path: "login",
    element: <Route children={Layout} />,
    children: [
      {
        index: true,
        element: <Login />,
      },
    ],
  },
];

const theme = createTheme();

const App = () => {
  const elements = useRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <PopupProvider>
        <AuthProvider>{elements}</AuthProvider>
      </PopupProvider>
    </ThemeProvider>
  );
};

export default App;
