import { API } from "./common";

interface LoginRequest {
  username: string;
  password: string;
}

interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

const login = async (payload: LoginRequest): Promise<LoginResponse> => {
  const { status, data } = await API.post<LoginResponse>(
    "/v1/auth/login",
    payload
  );
  if (status !== 200) throw Error(`status code: ${status}`);
  return data;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
};
